import { Logger } from '~/helpers/logger';
import { Cart } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';
import customeRemoveCouponFromCart from '~/diptyqueTheme/customQueries/magento/removeCouponFromCart';

export const removeCouponCommand = {
  execute: async (context: VsfContext, { currentCart, customQuery = { removeCouponFromCart: 'removeCouponFromCart' } }) => {
    Logger.debug('[Magento]: Remove coupon from cart', { currentCart });

    const { data, errors } = await context.$magento.api.customQuery({
      query: customeRemoveCouponFromCart,
      queryVariables: {
        input: {
          cart_id: currentCart.id
        }
      }
    });

    Logger.debug('[Result]:', { data });

    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedCart: data?.removeCouponFromCart?.cart as unknown as Cart,
      updatedCoupon: { code: '' },
      errors,
    };
  },
};
