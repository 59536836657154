import type { ApolloQueryResult } from '@apollo/client/core';

// eslint-disable-next-line no-duplicate-imports
import type { UseCartAddItemsParams } from '~/composables';
import type { VsfContext } from '~/composables/context';
import addProductsToCustomerCartFromRemoteMutation from '~/diptyqueTheme/customQueries/magento/addProductsToCustomerCartFromRemoteMutation';
import addProductsToGuestCartFromRemoteMutation from '~/diptyqueTheme/customQueries/magento/addProductsToGuestCartFromRemoteMutation';
import { Logger } from '~/helpers/logger';
import { useProductStore } from '~/modules/catalog/product/stores/product';
import type { AddExternalProductsToCartResponse } from '~/modules/checkout/types';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type { Cart, FreeShippingThresholdInfo, ProductResponse } from '~/modules/GraphQL/types';
import type { CustomQuery } from '~/types/core';

export const addItemsCommand = {
  execute: async (context: VsfContext, productItems: UseCartAddItemsParams, isAuthenticated: boolean, isInternalBulkProducts?: boolean) => {
    Logger.debug('[Magento]: Add external items to cart', {
      productItems
    });

    if (!productItems) {
      return;
    }

    const apiState = context.$magento.config.state;
    const customerStore = useCustomerStore();
    const cartId = apiState.getCartId();

    const productStore = useProductStore();
    const saveFreeShippingInfo = (info: FreeShippingThresholdInfo | null) => {
      productStore.setFreeShippingInfo(info);
    };

    let cartResponse;

    if (isInternalBulkProducts) {
      const getCartResponse = (productResponse: ProductResponse) => {
        const cartResponse = productResponse?.data?.addProductsToCart?.cart as unknown as Cart;

        if (cartResponse?.freeshipping_threshold_info) {
          saveFreeShippingInfo(cartResponse.freeshipping_threshold_info);
        }

        return cartResponse;
      };

      const cartInput = {
        cartId,
        cartItems: productItems
      };
      const customQuery = {
        addProductsToCart: 'addProductsToCart'
      };

      const productResponse = await context.$magento.api.addProductsToCart(cartInput, customQuery as CustomQuery);

      if (productResponse.errors?.length && !cartId && customerStore.isTokenExpired) {
        throw new Error(String('Your session has expired. Log in to continue your shopping.'));
      } else if (productResponse.errors?.length) {
        throw new Error(String(productResponse.errors[0].message));
      }

      const userErrors = productResponse?.data?.addProductsToCart?.user_errors || [];
      const cartItems = productResponse?.data?.addProductsToCart?.cart.items || [];

      const hasOutOfStockItem = cartItems.some((item) => item?.product?.stock_status === 'OUT_OF_STOCK');

      const hasUndefinedError = userErrors.some((error) => error.code === 'UNDEFINED');

      if (userErrors.length > 0 && !hasUndefinedError && !hasOutOfStockItem) {
        throw new Error(String(userErrors[0].message));
      }

      cartResponse = getCartResponse(productResponse as ProductResponse);
    } else {
      const getCartResponse = (response: AddExternalProductsToCartResponse) => {
        const cartResponse = isAuthenticated
          ? (response?.addToCustomerCartFromRemote?.cart as Cart)
          : (response?.addToGuestCartFromRemote?.cart as Cart);

        if (cartResponse?.freeshipping_threshold_info) {
          saveFreeShippingInfo(cartResponse.freeshipping_threshold_info);
        }

        return cartResponse;
      };

      const addToCartMutation = isAuthenticated ? addProductsToCustomerCartFromRemoteMutation : addProductsToGuestCartFromRemoteMutation;

      const { data, errors } = (await context.$magento.api.customQuery({
        query: addToCartMutation,
        queryVariables: {
          cartItems: productItems,
          cartMask: cartId
        }
      })) as ApolloQueryResult<AddExternalProductsToCartResponse>;

      if (errors?.length && !cartId && customerStore.isTokenExpired) {
        Logger.error('useCart/addItemsCommand response errors', errors[0], cartId, customerStore);
        throw new Error(String('Your session has expired. Log in to continue your shopping.'));
      } else if (errors?.length) {
        Logger.error('useCart/addItemsCommand response errors', errors[0]);
        throw new Error(String(errors[0].message));
      }

      const userErrors = isAuthenticated ? data?.addToCustomerCartFromRemote?.user_errors || [] : data?.addToGuestCartFromRemote?.user_errors || [];
      const cartItems = isAuthenticated ? data?.addToCustomerCartFromRemote?.cart.items || [] : data?.addToGuestCartFromRemote?.cart.items || [];

      const hasOutOfStockItem = cartItems.some((item) => item?.product?.stock_status === 'OUT_OF_STOCK');

      const hasUndefinedError = userErrors.some((error) => error.code === 'UNDEFINED');

      if (userErrors.length > 0 && !hasUndefinedError && !hasOutOfStockItem) {
        Logger.error('useCart/addItemsCommand', userErrors);
        throw new Error(String(userErrors[0].message));
      }

      cartResponse = getCartResponse(data as AddExternalProductsToCartResponse);
    }

    return cartResponse;
  }
};
