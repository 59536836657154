import { defineStore } from 'pinia';

import type { Cart, CartStockStatus } from '~/modules/GraphQL/types';

interface CustomerState {
  cart: Cart;
  cartStockStatus: CartStockStatus;
  is_data_loading: boolean;
  is_cart_loading: boolean;
  is_booxi_loading: boolean;
  initial_loading: boolean;
  is_item_adding: boolean;
  adding_items_count: number;
  selectedSkus: Array<string>;
}

export const useCartStore = defineStore('cart', {
  state: (): CustomerState => ({
    is_data_loading: false,
    is_cart_loading: false,
    is_booxi_loading: false,
    initial_loading: true,
    is_item_adding: false,
    adding_items_count: 0,
    cart: {
      id: '',
      is_virtual: false,
      total_quantity: 0,
      shipping_addresses: []
    },
    cartStockStatus: {
      id: '',
      items: []
    },
    selectedSkus: []
  }),

  actions: {
    addSku(sku: string) {
      if (!this.selectedSkus.includes(sku)) {
        this.selectedSkus.push(sku);
      }
    },

    removeSku(sku: string) {
      this.selectedSkus = this.selectedSkus.filter((item) => item !== sku);
    },

    clearSelectedSkus() {
      this.selectedSkus = [];
    },

    setSelectedSkus(skus: string[]) {
      this.selectedSkus = Array.isArray(skus) ? skus : [];
    }
  },

  getters: {
    getSelectedSkus: (state) => state.selectedSkus
  }
});
