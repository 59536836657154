import type { VsfContext } from '~/composables/context';
import applyCouponToCart from '~/diptyqueTheme/customQueries/magento/applyCouponToCartMutation';
import { Logger } from '~/helpers/logger';
import type { Cart } from '~/modules/GraphQL/types';

export const applyCouponCommand = {
  execute: async (context: VsfContext, { currentCart, couponCode, customQuery = { applyCouponToCart: 'customApplyCouponToCart' } }) => {
    Logger.debug('[Magento]: Apply coupon on cart', {
      couponCode,
      currentCart
    });

    const { data, errors } = await context.$magento.api.customQuery({
      query: applyCouponToCart,
      queryVariables: {
        input: {
          cart_id: currentCart.id,
          coupon_code: couponCode
        }
      }
    });

    Logger.debug('[Result]:', { data, errors });

    return {
      // todo: TS SUPPORT
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      updatedCart: data?.applyCouponToCart?.cart as unknown as Cart,
      updatedCoupon: { code: couponCode },
      errors
    };
  }
};
