import type { ComposableFunctionArgs } from '~/composables';
import type { VsfContext } from '~/composables/context';
import { Logger } from '~/helpers/logger';

export const loadTotalQtyCommand = {
  execute: async (context: VsfContext, params?: ComposableFunctionArgs<{}>) => {
    Logger.debug('[Magento]: Load cart total qty');

    const apiState = context.$magento.config.state;
    if (apiState.getCartId() && location.href.includes(apiState.getCartId())) {
      const { data }: any = await context.$magento.api.cartTotalQty(apiState.getCartId(), params?.customQuery ?? null);

      return data?.cart?.total_quantity ?? 0;
    }

    return 0;
  }
};
